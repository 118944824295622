import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatFeedback, ChatItem } from './types';

const initialState: AppSliceState = {
  chatItems: [],
  chatFeedbacks: {},
  isLoading: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clearChat(state) {
      state.isLoading = false;
      state.chatItems = [];
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateChatItem(state, action: PayloadAction<ChatItem>) {
      if (state.chatItems.find((item) => item.id === action.payload.id)) {
        state.chatItems = state.chatItems.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      } else {
        state.chatItems = [...state.chatItems, action.payload];
      }
    },
    setFeedback(state, action: PayloadAction<{ id: number; feedback: ChatFeedback }>) {
      state.chatFeedbacks[action.payload.id] = action.payload.feedback;
    },
  },
  selectors: {
    selectIsClearingAnswersDisabled: (state) => state.isLoading,
    selectIsLoading: (state) => state.isLoading,
    selectChatItems: (state) => state.chatItems,
    selectChatFeedbacks: (state) => state.chatFeedbacks,
  },
});

export interface AppSliceState {
  chatItems: ChatItem[];
  chatFeedbacks: { [id: number]: ChatFeedback };
  isLoading: boolean;
}

export const {
  selectIsClearingAnswersDisabled,
  selectIsLoading,
  selectChatItems,
  selectChatFeedbacks,
} = appSlice.selectors;

export const { clearChat, setIsLoading, updateChatItem, setFeedback } = appSlice.actions;

export const appReducer = appSlice.reducer;
