import { useState } from 'react';
import {
  FieldFrame,
  PrimaryButton,
  RadioField,
  TertiaryButton,
  TextField,
} from '../../pages/common';
import styles from './AnswerFeedbackForm.module.css';

interface Props {
  onSubmit: (selectedOption: string, otherFeedback: string) => void;
  onClose: () => void;
}

export const AnswerFeedbackForm = ({ onSubmit, onClose }: Props) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
  const [optionError, setOptionError] = useState<boolean>(false);
  const [otherFeedback, setOtherFeedback] = useState<string>('');
  const [otherFeedbackError, setOtherFeedbackError] = useState<boolean>(false);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (optionError) {
      setOptionError(false);
    }
    if (otherFeedbackError) {
      setOtherFeedbackError(false);
    }
    setSelectedOption(e.target.value);
  };

  const handleOtherFeedbackChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (otherFeedbackError) {
      setOtherFeedbackError(false);
    }
    setOtherFeedback(newValue ?? '');
  };

  const handleSubmit = () => {
    if (!selectedOption) {
      setOptionError(true);
      return;
    }
    if (selectedOption === 'other' && !otherFeedback.trim()) {
      setOtherFeedbackError(true);
      return;
    }
    onSubmit(selectedOption, otherFeedback);
  };

  const preventDefault = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <h2 className={styles.header}>Submit feedback</h2>
      <form onSubmit={preventDefault}>
        <RadioField
          name="feedback"
          value="incorrect"
          selectedValue={selectedOption}
          text="The response was incorrect or inaccurate."
          hasError={optionError}
          onChange={handleOptionChange}
        />
        <RadioField
          name="feedback"
          value="irrelevant"
          selectedValue={selectedOption}
          text="The response was irrelevant or off-topic."
          hasError={optionError}
          onChange={handleOptionChange}
        />
        <RadioField
          name="feedback"
          value="unclear"
          selectedValue={selectedOption}
          text="The response was unclear or confusing."
          hasError={optionError}
          onChange={handleOptionChange}
        />
        <RadioField
          name="feedback"
          value="incomplete"
          selectedValue={selectedOption}
          text="The response was incomplete or lacking detail."
          hasError={optionError}
          onChange={handleOptionChange}
        />
        <RadioField
          name="feedback"
          value="other"
          selectedValue={selectedOption}
          text="Other:"
          hasError={optionError}
          onChange={handleOptionChange}
        />
        <FieldFrame hasError={otherFeedbackError} disabled={selectedOption !== 'other'}>
          <TextField
            className={styles.otherFeedbackField}
            placeholder="Please provide details..."
            value={otherFeedback}
            disabled={selectedOption !== 'other'}
            onChange={handleOtherFeedbackChange}
          />
        </FieldFrame>
        <div className={styles.errorPanel}>
          {optionError && <div>Please select an option.</div>}
          {otherFeedbackError && <div>Please provide feedback.</div>}
        </div>
        <div className={styles.footer}>
          <TertiaryButton className={styles.retryButton} onClick={onClose} text="Cancel" />
          <PrimaryButton className={styles.retryButton} onClick={handleSubmit} text="Submit" />
        </div>
      </form>
    </>
  );
};
