import { AuthContextProps } from 'react-oidc-context';
import { ChatFeedback, ChatItem } from '../store/types';
import { postFeedbackApi } from './api';
import { FeedbackRequest } from './types';
import { getLastDraft, mapChatItemToChatRequestMessage } from './utils';

export const handleFeedbackRequest = (chatItems: ChatItem[], auth: AuthContextProps) => {
  return async (id: number, feedback: ChatFeedback) => {
    const indexOfItem = chatItems.findIndex((item) => item.id === id);

    // gets the slice of messages up to current message
    const currentChatItems = chatItems.slice(0, indexOfItem + 1);
    const messages = mapChatItemToChatRequestMessage(currentChatItems);

    const draft = getLastDraft(currentChatItems);

    const request: FeedbackRequest = {
      ...feedback,
      messages,
      draft,
    };

    await postFeedbackApi(request, {
      accessToken: auth.user?.access_token,
    });
  };
};
