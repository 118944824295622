import { useState } from 'react';
import { FaThumbsUp, FaRegThumbsUp, FaThumbsDown, FaRegThumbsDown } from 'react-icons/fa';
import { ChatFeedback } from '../../store/types';
import { useAppDispatch } from '../../store/hooks';
import { setFeedback } from '../../store/appSlice';
import { Popup } from '../../pages/common';
import { AnswerFeedbackForm } from './AnswerFeedbackForm';

import styles from './AnswerFeedback.module.css';

interface Props {
  id: number;
  feedback: ChatFeedback | undefined;
  onFeedback: (payload: ChatFeedback) => void;
}

export const AnswerFeedback = ({ id, feedback, onFeedback }: Props) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleThumbsUp = async () => {
    const payload: ChatFeedback = { rating: 'positive' };
    dispatch(setFeedback({ id, feedback: payload }));
    onFeedback(payload);
  };

  const handleThumbsDown = () => {
    setShowModal(true);
  };

  const submitModal = (selectedOption: string, otherFeedback: string) => {
    const payload: ChatFeedback = { rating: 'negative', selectedOption, otherFeedback };

    dispatch(
      setFeedback({
        id,
        feedback: payload,
      })
    );
    onFeedback(payload);
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.thumbContainer}>
      {(!feedback || feedback?.rating === 'none') && (
        <>
          <button className={styles.thumbButton} onClick={handleThumbsUp}>
            <FaRegThumbsUp />
          </button>
          <button className={styles.thumbButton} onClick={handleThumbsDown}>
            <FaRegThumbsDown />
          </button>
        </>
      )}
      {feedback?.rating === 'positive' && (
        <>
          <FaThumbsUp />
          <div className={styles.animatedText}>Good response</div>
        </>
      )}
      {feedback?.rating === 'negative' && (
        <>
          <FaThumbsDown />
          <div className={styles.animatedText}>Bad response</div>
        </>
      )}
      {showModal && (
        <Popup>
          <AnswerFeedbackForm onSubmit={submitModal} onClose={closeModal} />
        </Popup>
      )}
    </div>
  );
};
