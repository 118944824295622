import { useEffect, useRef } from 'react';

export const useFocusTrap = () => {
  const focusTrapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (focusTrapRef.current === null) return;

    const focusableElements = focusTrapRef.current.querySelectorAll<HTMLElement>(
      'button, [href], input, textarea, [tabindex]:not([tabindex="-1"])'
    );

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    const handleTabNavigation = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        const activeElement = document.activeElement as HTMLElement;
        if (!Array.from(focusableElements).includes(activeElement)) {
          e.preventDefault();
          firstElement.focus();
          return;
        }

        if (e.shiftKey) {
          if (activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          if (activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    firstElement?.focus();

    document.addEventListener('keydown', handleTabNavigation);
    document.body.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('keydown', handleTabNavigation);
      document.body.style.overflow = 'auto';
    };
  }, []);

  return focusTrapRef;
};
