import { ReactNode } from 'react';
import { Stack } from './Stack';
import styles from './FieldFrame.module.css';

interface Props {
  hasError?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

export const FieldFrame = ({ hasError = false, disabled = false, children }: Props) => {
  const className = [hasError && styles.hasError, disabled && styles.disabled, styles.fieldInnerBox]
    .filter((v) => v)
    .join(' ');
  return (
    <div className={styles.fieldContainer}>
      <Stack horizontal className={className}>
        {children}
      </Stack>
    </div>
  );
};
