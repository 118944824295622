import { MouseEventHandler } from 'react';

import styles from './TertiaryButton.module.css';

interface TertiaryButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: MouseEventHandler;
  text: string;
}

export const TertiaryButton = ({
  className,
  disabled = false,
  onClick,
  text,
}: TertiaryButtonProps) => {
  return (
    <button
      className={`${className ? className + ' ' : ''}${styles.tertiaryButton}`}
      disabled={disabled}
      onClick={onClick}
    >
      <span className={styles.tertiaryButtonText}>{text}</span>
    </button>
  );
};
