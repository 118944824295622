import styles from './RadioField.module.css';

interface Props {
  name: string;
  value: string;
  selectedValue: string | undefined;
  text: string;
  hasError?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioSymbol = ({ hasError }: { hasError: boolean }) => (
  <svg viewBox="0 0 21 21" height="21" width="21" className={styles.radioButton}>
    <g id="radio-circle" transform="translate(.5 0)">
      <circle
        className={styles.radioDot}
        fill={hasError ? '#de000c' : '#0067C5'}
        cx="10"
        cy="10"
        r="5.7"
      />
      <circle
        cx="10"
        cy="10"
        r="8.9"
        stroke={hasError ? '#de000c' : '#0067C5'}
        strokeWidth="2"
        fill="transparent"
      />
    </g>
  </svg>
);

export const RadioField = ({
  name,
  value,
  selectedValue,
  text,
  hasError = false,
  onChange,
}: Props) => {
  return (
    <div className={styles.radioField}>
      <input
        id={`input-${value}`}
        className={styles.input}
        type="radio"
        name={name}
        value={value}
        checked={value === selectedValue}
        onChange={onChange}
      />
      <label htmlFor={`input-${value}`} className={`${hasError ? styles.hasError : ''}`}>
        <RadioSymbol hasError={hasError} />
        {text}
      </label>
    </div>
  );
};
