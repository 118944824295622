import { CaseDraft, ChatAnswerType, ChatItem } from '../store/types';
import { ChatRequestMessage } from './types';

export const mapChatItemToChatRequestMessage = (chatItems: ChatItem[]): ChatRequestMessage[] => {
  return chatItems
    .filter((item) => item.answer.type === ChatAnswerType.ok)
    .flatMap((item) => [
      { content: item.question, role: 'user' },
      {
        content: item.answer.content,
        role: 'assistant',
      },
    ]);
};

// finds last not sen draft
export const getLastDraft = (chatItems: ChatItem[]): CaseDraft | undefined => {
  let draft: CaseDraft | undefined;

  for (const item of chatItems.toReversed()) {
    if (item.answer.draft) {
      draft = item.answer.draft;
      break;
    }
    if (item.answer.type === ChatAnswerType.caseCreated) {
      break;
    }
  }

  return draft;
};
