import styles from './Chat.module.css';
import { QuestionInput } from '../../components/QuestionInput';
import { useAuth } from 'react-oidc-context';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectChatItems, selectIsLoading } from '../../store/appSlice';
import { handleRequest } from '../../api/request';
import { handleFeedbackRequest } from '../../api/feedback';
import { EmptyChat } from '../../components/EmptyChat/EmptyChat';
import { ChatMessageStream } from '../../components/ChatMessageStream/ChatMessageStream';
import { useRef } from 'react';

const Chat = () => {
  const auth = useAuth();

  const isLoading = useAppSelector(selectIsLoading);
  const chatItems = useAppSelector(selectChatItems);

  const questionInputRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  const makeApiRequest = handleRequest(dispatch, chatItems, auth);
  const makeFeedbackRequest = handleFeedbackRequest(chatItems, auth);

  if (!auth?.isAuthenticated) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.chatRoot}>
        <div className={styles.chatContainer}>
          {chatItems.length === 0 ? (
            <EmptyChat makeApiRequest={makeApiRequest} />
          ) : (
            <ChatMessageStream
              makeApiRequest={makeApiRequest}
              makeFeedbackRequest={makeFeedbackRequest}
              questionInputRef={questionInputRef}
            />
          )}
          <div className={styles.chatInput}>
            <QuestionInput
              clearOnSend
              placeholder="Message Synergi Life AI"
              disabled={isLoading}
              onSend={(question) => makeApiRequest(question)}
              questionInputRef={questionInputRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
