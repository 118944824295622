import DOMPurify from 'dompurify';
import { useAuth } from 'react-oidc-context';
import { triggerSigninRedirect } from '../../auth/helpers';
import applicationSettings from '../../infrastructure/config';
import { Stack, StackItem } from '../../pages/common';
import { ChatAnswer, ChatAnswerType, ChatFeedback } from '../../store/types';
import { AnswerFeedback } from '../AnswerFeedback/AnswerFeedback';

import styles from './Answer.module.css';

interface Props {
  id: number;
  answer: ChatAnswer;
  feedback: ChatFeedback | undefined;
  onFeedback: (payload: ChatFeedback) => void;
}

export const Answer = ({ id, answer, feedback, onFeedback }: Props) => {
  const auth = useAuth();
  const messageContent = answer.content ?? '';
  const sanitizedAnswer = DOMPurify.sanitize(messageContent, {
    ALLOWED_TAGS: ['em', 'strong', 'code', 'pre'],
    ALLOWED_ATTR: [],
  })
    .replace(/\*\*/g, '') // Remove remaining **
    .replace(/`/g, ''); // Remove any remaining ` backticks
  return (
    <Stack>
      <StackItem className={styles.answerContainer} grow>
        <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswer }} />

        {answer.type === ChatAnswerType.caseCreated && !!applicationSettings.synergiLifeCaseUrl && (
          <a
            href={applicationSettings.synergiLifeCaseUrl! + answer.caseId}
            target="_blank"
            rel="noreferrer"
          >
            Click here to open your case in Synergi Life
          </a>
        )}

        {answer.type === ChatAnswerType.unauthorized && (
          <a className={styles.link} onClick={triggerSigninRedirect(auth)}>
            Click here to login again
          </a>
        )}
      </StackItem>
      <StackItem>
        {answer.type === ChatAnswerType.ok && (
          <AnswerFeedback id={id} feedback={feedback} onFeedback={onFeedback} />
        )}
      </StackItem>
    </Stack>
  );
};
