import applicationSettings from '../infrastructure/config';
import { ChatRequest, FeedbackRequest } from './types';

const getHeaders = (accessToken?: string): Record<string, string> => ({
  'Content-Type': 'application/json',
  ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
});

export async function postChatApi(
  request: ChatRequest,
  parameters?: RequestParameters
): Promise<Response> {
  return await fetch(`${applicationSettings.applicationUrl}/api/chat`, {
    method: 'POST',
    headers: getHeaders(parameters?.accessToken),
    body: JSON.stringify(request),
  });
}

export async function postFeedbackApi(
  request: FeedbackRequest,
  parameters?: RequestParameters
): Promise<Response> {
  return await fetch(`${applicationSettings.applicationUrl}/api/feedback`, {
    method: 'POST',
    headers: getHeaders(parameters?.accessToken),
    body: JSON.stringify(request),
  });
}

export interface RequestParameters {
  accessToken?: string;
}
