import { ReactNode } from 'react';
import { useFocusTrap } from './useFocusTrap';
import styles from './Popup.module.css';

interface Props {
  children: ReactNode;
}

export const Popup = ({ children }: Props) => {
  const focusTrapRef = useFocusTrap();

  return (
    <div className={styles.overlay}>
      <div className={styles.popupContent} ref={focusTrapRef}>
        {children}
      </div>
    </div>
  );
};
